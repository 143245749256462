import React from 'react';

import ServicesLayout from '../../components/ServicesLayout';
import CobraImage from '../../images/image_COBRA.jpg';


const Enrollment = () => (

          <ServicesLayout
    title="COBRA Administration"
    bannerTitle="COBRA Administration"
    bannerCopy="Donec facilisis tincidunt orci non vestibulum. Phasellus pulvinar ante eu vulputate dapibus. Quisque sodales metus at urna dictum consectetur."
  >
    <h2 className="inner-page__heading">COBRA Administration</h2>
    <p className="inner-page__lead">Streamlined administration, participant communication and plan reporting for unlimited COBRA needs.</p>
    <img className="inner-page__hero" src={CobraImage} alt="Man and women using Cobra Administration on laptop computer" />
    <p>Tri-Star can streamline and take over all of your COBRA compliance, notification, billing and collection responsibilities for an unlimited number of plans. <strong>Your HR team is freed to focus on servicing employees.</strong></p>

    <p>Tri-Star Service Highlights:</p>
    <ul>
      <li><strong>Unlimited Plan Administration</strong> with any combination of carriers, plans and tiers</li>
      <li><strong>COBRA Qualification & Enrollment Communication</strong> to ensure participants are educated on their ability to continue coverage</li>
      <li><strong>HIPAA Certificates of Creditable Coverage</strong> to facilitate portability of coverage for participants changing employment or health plans</li>
      <li><strong>Premium Collection & Remittance</strong> for seamless billing and payment of premiums</li>
      <li><strong>Multiple Premium Payment Options</strong> via ACH, Pay Pal or through regular USPS mail</li>
      <li><strong>Monthly Reporting</strong> with easy access to eligibility reports and employee coverage extension  for employers and insurance carriers</li>
      <li><strong>Live Customer Service</strong> for educating and supporting plan beneficiaries</li>
      <li><strong>Eligibility Updates</strong> to insurance carriers through standard HIPAA834 or other required formats</li>
    </ul>
    <p className="inner-page__lead">How can Tri-Star help administer your employee benefits? Call us at <a href="tel:3145764022">314.576.4022</a> or email us at <a href="mailto:info@tri-starsystems.com">info@tri-starsystems.com</a>.</p>
  </ServicesLayout>
);

export default Enrollment
